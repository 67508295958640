// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: "Chairman's Message",
  nakedPageSlug: 'chairmans-message',
  pageAbstract:
    'On behalf of LWES team, I welcome you to our Innovative School and assure that I and my team are committed to support our students to overcome all obstacles and be progressive and pioneering for this world to be a better place.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    image1: file(relativePath: { eq: "homepage/chairman.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Chairman's Message</h1>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <p>
          We are living in extraordinary times. Upheavals and intentions surpass
          our imagination.
        </p>
        <p>
          World is unquestionably striving towards creating equality among boys
          and girls in terms of intelligence, efficacy, capacity, yet, a lot is
          to be done by schools, society and world to understand that women
          exhibit and have gift of traits like delegating, collaborating,
          communicating and finding solutions under all conditions. If nurtured,
          can be the powerful mediums of world problem solutions.
        </p>
        <p>
          Learning Wings Education Systems nurtures a dream to celebrate the
          difference between the boys and girls through education that creates a
          new world.
        </p>
        <p>
          We aspire to foster and fiercely protect the bright spontaneity in
          each child. Students are encouraged to develop their instruments of
          knowledge by using all possible sources from modern technology to
          traditional methods and gain essential skills to be successful in
          whatever they choose to be.
        </p>
        
        <p>
          Brigade of strong teachers inspire each child every day to nurture a
          dream and develop skills to be stronger, resilient, fearless and more
          spirited than ever before and be role models for many .
        </p>
        <p>
          World-class facilities in sensitively designed classrooms to
          state-of-the-art play grounds are the extension of our ethos to
          provide children with the best in all areas of learning. Students should
          feel challenged, become inquirers and help redefine the conventions
          and prejudices to be pioneers of causes for all aspects of life. They
          are to be the movement and create strong frontiers, which the world is
          yet to imagine.
        </p>
        <p>
          On behalf of LWES team, I welcome you to our Innovative School and assure
          that I and my team are committed to support our students to overcome
          all obstacles and be progressive and pioneering for this world to be a
          better place.
        </p>
        <p>
          Warm regards
          <br />
          Ajay Bhatia
        </p>
      </Col>
      <Col sm={24} md={8}>
        <div className="mask-p">
          <Img
            fluid={props.data.image1.childImageSharp.fluid}
            title="Sri M"
            alt="Sri M"
            className="test"
          />
        </div>
        <p style={{ textAlign: 'center' }}>
          <strong>Mr. Ajay Bhatia</strong>
          <br />
          Chairman, LWES
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
